<template>
	<layout-default>
		<div>
			<div class="uk-position-center" v-if="loading == true">
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div class="" v-if="loading == false">
				<div class="uk-width-1-1 t-bg-4">
					<div class="p-pt-1 p-pb-2">
						<div class="uk-container uk-container-large">
							<div class="uk-grid">
								<div class="uk-width-1-1 uk-width-auto@s">
									<a class="p-button p-button-text p-pl-0" style="color:#FFF;" href="#stuur-ons-een-bericht"><i class="pi pi-envelope p-mr-2"></i> Stuur ons een bericht</a>
								</div>
								<div class="uk-width-expand@s"></div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-3"></div>

				<div class="uk-container uk-container-large">
					<div class="p-mb-3">
						<div v-if="currentContact.length > 0">
							<div class="uk-grid uk-flex-left" v-match-heights="{ el: ['.eqheight0'] }" v-if="currentContact.length > 0">
								<div class="uk-width-2-3@m" v-for="(contact, index) in currentContact" :key="index">
									<div class="p-mb-5 typography-main">
										<h1>Uw verkoopadviseur:</h1>
									</div>
									<Card>
										<template slot="content">
											<div class="eqheight0">
												<div class="uk-grid">
													<div class="uk-width-1-3@s  uk-width-1-5@m  uk-width-1-5@l" v-if="contact.photo">
														<img :src="contact.photo.data.full_url" style="width:100%;" />
													</div>
													<div class="uk-width-expand@s p-mb-3">
														<h2 class="p-mb-2">{{ contact.name_first }} {{ contact.name_last }}</h2>
														<div class="p-mb-4" v-if="contact.function">
															<em>{{ contact.function }}</em>
														</div>
														<div class="p-mb-4" v-if="contact.region">
															<span class="t-fg-1">{{ contact.region }} </span>
														</div>
														<div class="p-mb-2" v-if="contact.phone">
															<i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
														</div>
														<div class="p-mb-2" v-if="contact.email">
															<i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
														</div>
													</div>
													<div class="p-mb-3 uk-visible@m" style="width:180px;">
														<div class="p-mb-3">
															<qrcode
																:value="generateVCard(contact)"
																:options="{
																	width: 140,
																	color: {
																		dark: '#2a7ab0',
																		light: '#ffffff',
																	},
																}"
															></qrcode>
														</div>
														<div class="p-text-center t-fg-1">Zet in uw telefoon contacten</div>
													</div>
												</div>
											</div>
										</template>
									</Card>
								</div>

								<div class="uk-width-1-3@m" v-if="userExcelRow.Wholesaler_ID">
									<div class="p-mb-6">
										<div class="p-mb-5 typography-main">
											<h1>Uw grossier:</h1>
										</div>

										<div>
											<div v-for="(wholesaler, index) in currentWholesaler" :key="index" v-if="currentWholesaler.length > 0">
												<Card>
													<template slot="content">
														<div class="eqheight0">
															<div class="p-mb-4" v-if="wholesaler.logo">
																<img :src="wholesaler.logo.data.full_url" style="max-height:64px;max-width:250px;" />
															</div>
															<div class="p-mb-2" v-if="wholesaler.phone">
																<i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1881'">{{ wholesaler.phone }}</a>
															</div>
															<div class="p-mb-2" v-if="wholesaler.url">
																<i class="pi pi-external-link p-mr-2"></i> <a :href="wholesaler.url" target="_blank">{{ wholesaler.url }}</a>
															</div>
														</div>
													</template>
												</Card>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="p-mb-3">
						<div class="uk-grid uk-flex-left" v-match-heights="{ el: ['.eqheight_vteam'] }">
							<div class="uk-width-2-3@l p-mb-5">
								<div class="p-mb-5 typography-main">
									<h3>Het verkoop team:</h3>
								</div>
								<div class="uk-grid uk-grid-small uk-flex-center">
									<div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l p-mb-3" v-for="(contact, index) in salesTeam" :key="index">
										<Card :style="{ border: '3px solid ' + contact.color }">
											<template slot="content">
												<div class="eqheight_vteam">
													<div class="uk-grid typography-main" style="font-size:12px;">
														<div class="uk-width-1-4" v-if="contact.photo">
															<img :src="contact.photo.data.full_url" style="width:100%;" />
														</div>
														<div class="uk-width-expand">
															<h2 class="p-mb-2">{{ contact.name_first }} {{ contact.name_last }}</h2>
															<div class="p-mb-4" v-if="contact.function">
																<em>{{ contact.function }} </em>
															</div>
															<div class="p-mb-4" v-if="contact.region">
																<span class="t-fg-1">{{ contact.region }} </span>
															</div>
															<div class="p-mb-2" v-if="contact.phone">
																<i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
															</div>
															<div class="p-mb-2" v-if="contact.email">
																<i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
															</div>
														</div>
													</div>
												</div>
											</template>
										</Card>
									</div>
								</div>
							</div>

							<div class="uk-width-1-3@l p-mb-3">
								<div class="p-mb-5 typography-main">
									<h3 v-if="userExcelRow.Wholesaler_ID">Overige grossiers:</h3>
									<h3 v-else>Grossiers:</h3>
								</div>

								<div class="uk-grid uk-grid-small typography-main" style="font-size:14px;">
									<div class="uk-width-1-1 uk-width-1-2@m uk-width-1-1@l p-mb-3" v-for="(wholesaler, index) in otherWholesalers" :key="index">
										<Card>
											<template slot="content">
												<div class="">
													<div class="p-mb-4" v-if="wholesaler.logo">
														<img :src="wholesaler.logo.data.full_url" style="max-height:48px;max-width:150px;" />
													</div>
													<div class="p-mb-2" v-if="wholesaler.phone">
														<i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1881'">{{ wholesaler.phone }}</a>
													</div>
													<div class="p-mb-2" v-if="wholesaler.url">
														<i class="pi pi-external-link p-mr-2"></i> <a :href="wholesaler.url" target="_blank">{{ wholesaler.url }}</a>
													</div>
												</div>
											</template>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="uk-container uk-container-large" v-if="1 == 2">
					<div class="p-mb-6">
						<div class="p-mb-5 typography-main">
							<h3>Field Sales Manager:</h3>
						</div>

						<div class="uk-grid">
							<div class="uk-width-2-3@l" v-match-heights="{ el: ['.eqheight0'] }" v-for="(contact, index) in contacts" :key="index" v-if="contact.function == 'Field Sales Manager'">
								<div class="uk-grid uk-grid-small">
									<div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-2@l p-mb-3">
										<Card>
											<template slot="content">
												<div class="eqheight_vteam">
													<div class="uk-grid typography-main" style="font-size:12px;">
														<div class="uk-width-1-4" v-if="contact.photo">
															<img :src="contact.photo.data.full_url" style="width:100%;" />
														</div>
														<div class="uk-width-expand">
															<h2 class="p-mb-2">{{ contact.name_first }} {{ contact.name_last }}</h2>
															<div class="p-mb-4" v-if="contact.function">
																<em>{{ contact.function }} </em>
															</div>
															<div class="p-mb-4" v-if="contact.region">
																<span class="t-fg-1">{{ contact.region }} </span>
															</div>
															<div class="p-mb-2" v-if="contact.phone">
																<i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
															</div>
															<div class="p-mb-2" v-if="contact.email">
																<i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
															</div>
														</div>
													</div>
												</div>
											</template>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="uk-container uk-container-large" id="stuur-ons-een-bericht">
					<div class="uk-grid">
						<div class="uk-width-1-2@l p-pb-6">
							<div>
								<img src="@/assets/KAART_NL.svg?v=2" />
							</div>

							<div class="p-mb-5 typography-main">
								<h1>Stuur ons een bericht</h1>
							</div>

							<Card>
								<template slot="content">
									<div class="p-mb-6">
										<div v-if="formsend == true">
											<div class="p-mb-5 typography-main">
												<div v-html="content[0].html"></div>
											</div>
										</div>

										<div v-if="formsend != true">
											<div class="typography-main">
												<ValidationObserver ref="observer" v-slot="{ invalid }">
													<div>
														<div class="p-fluid p-grid">
															<div class="p-field p-col-12 p-md-6">
																<div>
																	<ValidationProvider v-slot="v" rules="required" name="Voornaam">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<InputText type="text" v-model="form.Voornaam" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
																			<label>Uw voornaam</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field p-col-12 p-md-6">
																<div>
																	<ValidationProvider v-slot="v" rules="required" name="Achternaam">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<InputText type="text" v-model="form.Achternaam" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
																			<label>Uw Achternaam</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field p-col-12 p-md-6">
																<div>
																	<ValidationProvider v-slot="v" rules="required|email" name="E-mail">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<InputText type="email" v-model="form.Email" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
																			<label>E-mail</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field p-col-12 p-md-6">
																<div>
																	<ValidationProvider v-slot="v" rules="required" name="Telefoon">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<InputText type="text" v-model="form.Telefoon" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
																			<label>Telefoon</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field p-col-12">
																<div>
																	<ValidationProvider v-slot="v" rules="required" name="Onderwerp">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<InputText type="text" v-model="form.Onderwerp" :class="v.errors.length == 0 ? '' : 'p-invalid'" />
																			<label>Onderwerp</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field p-col-12">
																<div>
																	<ValidationProvider v-slot="v" rules="required" name="Bericht">
																		<span class="p-float-label p-input-icon-right">
																			<i class="pi pi-exclamation-triangle" v-if="v.errors.length > 0" v-tooltip.bottom="v.errors[0]" />
																			<Textarea type="text" v-model="form.Bericht" :class="v.errors.length == 0 ? '' : 'p-invalid'" :autoResize="true" style="min-width:100%;" />
																			<label>Vragen of opmerkingen</label>
																		</span>
																	</ValidationProvider>
																</div>
															</div>

															<div class="p-field">
																<div>
																	<Button label="Verzend" @click="submitForm()" :disabled="invalid" />
																</div>
															</div>
														</div>
													</div>
												</ValidationObserver>
											</div>
										</div>
									</div>
								</template>
							</Card>
						</div>

						<div class="uk-width-1-2@l">
							<div class="p-mb-6">
								<div class="p-mb-5 typography-main">
									<h1>Vragen over...</h1>
								</div>

								<div class="uk-grid uk-grid-small typography-main" style="font-size:14px;" v-match-heights="{ el: ['.eqheight'] }">
									<div class="uk-width-1-2@s uk-width-1-1@m p-mb-3">
										<Card>
											<template slot="content">
												<div class="eqheight p-mb-3">
													<h2>Bestellingen:</h2>
													<div class="p-mb-3">
														<small><br /></small>
													</div>
													<h3 class="p-mb-2">Afdeling Verkoop Binnendienst</h3>
													<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1881'">+31 (0)297 54 1881</a></div>
													<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:verkoop.dhz@ppg.com'">verkoop.dhz@ppg.com</a></div>
												</div>
											</template>
										</Card>
									</div>
									<div class="uk-width-1-2@s uk-width-1-1@m p-mb-3">
										<Card>
											<template slot="content">
												<div class="eqheight p-mb-3">
													<h2>Retouren en creditnota’s:</h2>
													<div class="p-mb-3">
														<small>(via PPG rechtstreeks)<br /></small>
													</div>
													<h3 class="p-mb-2">Afdeling Administatie</h3>
													<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1241'">+31 (0)297 54 1241</a></div>
													<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:admin.dhz@ppg.com'">admin.dhz@ppg.com</a></div>
												</div>
											</template>
										</Card>
									</div>
									<div class="uk-width-1-2@s uk-width-1-1@m p-mb-3">
										<Card>
											<template slot="content">
												<div class="eqheight p-mb-3">
													<h2>Technische vragen:</h2>
													<div class="p-mb-3">
														<small>Voor vragen en ondersteuning m.b.t. de technische aspecten van verf of kleurrecepten.<br /></small>
													</div>
													<h3 class="p-mb-2">Afdeling Technisch Centrum</h3>
													<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1234'">+31 (0)297 54 1234</a></div>
													<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:technicalsupportBNL@ppg.com'">technicalsupportBNL@ppg.com</a></div>
												</div>
											</template>
										</Card>
									</div>
									<div class="uk-width-1-2@s uk-width-1-1@m p-mb-3">
										<Card>
											<template slot="content">
												<div class="eqheight p-mb-3">
													<h2>Mengmachine:</h2>
													<div class="p-mb-3">
														<small>Voor vragen over uw kleurmengmachine.<br /></small>
													</div>
													<h3 class="p-mb-2">Afdeling Colour Services</h3>
													<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)20 407 5337'">+31 (0)20 407 5337</a></div>
													<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:colourservices@ppg.com'">colourservices@ppg.com</a></div>
												</div>
											</template>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Textarea,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			loading: false,
			user: {
				email: "",
				password: "",
				password2: "",
			},
			userExcelRow: {},
			wholesalers: [],
			contacts: [],
			personen: [{}],
			grossiers: [{}],
			settings: [],
			form: {
				Voornaam: null,
				Achternaam: null,
				Email: null,
				Telefoon: null,
				Onderwerp: null,
				Bericht: null,
			},
			formsend: false,
			content: [{}],
		};
	},
	props: {},
	methods: {
		generateVCard(contact) {
			let vcardText = "BEGIN:VCARD" + "\nVERSION:2.1" + "\nFN:" + contact.name_first + " " + contact.name_last + "\nN:" + contact.name_last + ";" + contact.name_first + "\nTITLE:" + contact.function + "\nTEL;CELL:" + contact.phone + "\nEMAIL;WORK;INTERNET:" + contact.email + "\nEND:VCARD";
			console.log(vcardText);
			return vcardText;
		},
		submitForm() {
			let self = this;
			let observer = self.$refs["observer"];
			console.log(observer);
			console.log(self.user);
			if (observer.flags.valid == true) {
				let body = "";
				Object.keys(self.form).forEach((key) => {
					body = body + "<b>" + key + "</b>: " + self.form[key] + "<br>";
				});
				body = body + "<br><b>User (logged in)</b>:<br>";
				body = body + "<b>id</b>: " + self.user.id + "<br>";
				body = body + "<b>email</b>: " + self.user.email + "<br>";
				body = body + "<b>customer_id</b>: " + self.user.customer_id + "<br>";
				body = body + "<b>company</b>: " + self.user.company + "<br>";
				body = body + "<b>first_name</b>: " + self.user.first_name + "<br>";
				body = body + "<b>last_name</b>: " + self.user.last_name + "<br>";

				console.log(body);

				Directus.client2.api
					.post("/mail", {
						to: ["ppgretailnl@ppg.com", "dev@targateam.nl"],
						from: "noreply@ppgretailnl.com",
						subject: "Bericht via ppg-retail.nl",
						body: body,
						type: "html",
						data: {},
					})
					.then((response) => {
						self.formsend = true;
					});
			}
		},
	},
	computed: {
		currentContact() {
			if (this.userExcelRow) {
				return this.contacts.filter((contact) => {
					return contact.id == this.userExcelRow.Contact_ID && contact.function == "Allround Verkoopadviseur";
				});
			}
			return false;
		},
		currentWholesaler() {
			if (this.userExcelRow) {
				return this.wholesalers.filter((wholesaler) => {
					return wholesaler.id == this.userExcelRow.Wholesaler_ID && wholesaler.id != 5;
				});
			}
			return false;
		},
		otherWholesalers() {
			if (this.currentWholesaler.length > 0) {
				return this.wholesalers.filter((wholesaler) => {
					return wholesaler.id != this.currentWholesaler[0].id && wholesaler.id != 5;
				});
			} else {
				return this.wholesalers.filter((wholesaler) => {
					return wholesaler.id != 5;
				});
			}
		},
		salesTeam() {
			return this.contacts.filter((contact) => {
				return contact.function == "Allround Verkoopadviseur";
			});
		},
	},
	created() {
		let self = this;

		self.user = self.$route.params.user;

		//self.form.Voornaam = self.user.first_name;
		//self.form.Achternaam = self.user.last_name;
		self.form.Email = self.user.email;

		Directus.client.api
			.get("/items/settings", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				"fields": "*,clients_xls.*",
			})
			.then((response) => {
				self.settings = response.data;
				//console.log(self.settings[0]);

				Axios.post(self.remote_addr + "/development/excel/", {
					email: self.user.email,
				})
					.then((response) => {
						//console.log("response", response);
						if (response.data.status == "success") {
							self.userExcelRow = response.data.data;
						}

						var resizeEvent = new Event("resize");
						window.dispatchEvent(resizeEvent);
					})
					.catch((error) => {
						console.log("error", error);
					});
			});

		Directus.client.api
			.get("/items/contacts", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				"fields": "*,photo.*",
			})
			.then((response) => {
				this.contacts = response.data;
			});

		Directus.client.api
			.get("/items/wholesalers", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				"fields": "*,logo.*",
			})
			.then((response) => {
				this.wholesalers = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "contact-bedankt",
				"fields": "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function() {},
	watch: {
		userExcelRow(to, from) {
			this.form.Telefoon = to["Tel. Nummer"];
		},
	},
};
</script>

<style lang="scss">
.p-card-content {
	padding: 0px !important;
}
</style>
