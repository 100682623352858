<template>
	<div class="LayoutLogin">
		<div>
			<div>
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
};
</script>

<style scoped></style>
