<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid uk-grid-small typography-main">
						<div class="uk-width-expand p-pb-3">
							<div class="p-pb-3">
								<h1>
									Prijslijsten <small v-if="currentBrand[0] && currentBrand[0].brand_id">{{ currentBrand[0].brand_id.name }}</small>
								</h1>
							</div>

							<div class="uk-width-1-1 p-pb-3">
								<div v-html="content[0].html"></div>
							</div>
						</div>
						<div class="typography-main uk-width-1-1 p-pb-6">
							<div
								class="uk-grid uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m p-mb-6"
								v-if="!currentBrand[0]"
							>
								<div
									class="p-mb-5"
									v-for="(brand, index) in brands"
									:key="index"
									@click="$router.push({ name: 'pricelists', params: { brand_slug: brand.brand_id.slug } })"
								>
									<Card>
										<template slot="content">
											<div
												class="uk-position-relative"
												v-aspect-ratio="'3:2'"
												v-if="brand.brand_id && brand.brand_id.logo && brand.brand_id.logo.data"
											>
												<img
													class="uk-position-absolute"
													:src="remote_addr + brand.brand_id.logo.data.asset_url + '?w=800&h=400&q=100&f=contain'"
													style="position: absolute; padding:20px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;
													cursor:pointer;
													"
												/>
											</div>
											<div
												class="uk-position-relative"
												v-aspect-ratio="'3:2'"
												v-else
											>
												<div
													class="uk-position-absolute"
													style="position: absolute; padding:20px; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; cursor:pointer;"
												>{{ brand.brand_id.name }}</div>
											</div>
										</template>
									</Card>
								</div>
							</div>

							<div
								v-if="brandSlug"
								style="margin:-7px;"
							>
								<div class="p-mb-4">
									<DataView
										:value="pricelists"
										:layout="layout"
										:rows="50"
									>
										<template #grid="slotProps">
											<div
												class="uk-width-1-1"
												v-if="slotProps.data.brand_id.slug == brandSlug"
											>
												<div class="p-p-2">
													<Card>
														<template slot="content">
															<div class="uk-grid uk-grid-small uk-flex-middle">
																<div class="uk-width-auto">
																	<div :class="'fi fi-size-xs fi-' + getExtension(slotProps.data.file.filename_download)">
																		<div class="fi-content">
																			{{ getExtension(slotProps.data.file.filename_download) }}
																		</div>
																	</div>
																</div>
																<div
																	class="uk-width-expand p-text-nowrap p-text-truncate"
																	style="font-size:0.85rem;"
																>
																	<div>
																		{{ slotProps.data.name }}
																	</div>
																</div>
																<div class="uk-width-auto"><Button
																		class="p-button-outlined p-button-small"
																		label="Download"
																		icon="pi pi-download"
																		@click="openFile(slotProps.data)"
																	></Button></div>
															</div>
														</template>
													</Card>
												</div>
											</div>
										</template>
									</DataView>
								</div>

								<div class="p-mb-4">
									<Button
										icon="pi pi-arrow-left"
										class="p-button-outlined"
										label="Prijslijsten"
										@click="$router.push({ name: 'pricelists', params: { brand_slug: null } })"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog
				:header="lightbox.title"
				:visible.sync="lightbox.visible"
				:style="{ maxWidth: '95vw' }"
				:modal="true"
			>
				<div class="p-m-0">
					<pdf
						:src="lightbox.pdf"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<style>
.p-dataview .p-dataview-content {
	background: transparent !important;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import pdf from "vue-pdf";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		pdf,

		ProgressSpinner,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		SplitButton,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: false,
			brandSlug: null,
			brands: [],
			pricelists: [],
			menu: {},
			displayModal: false,
			lightbox: {
				title: null,
				visible: false,
				image: null,
				pdf: null,
			},

			files: [],
			folders: [],
			folder: null,

			layout: "grid",
			sortKey: null,
			sortOrder: null,
			sortField: null,
			content: [{}],
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},

		openFile(file) {
			console.log(file);
			if (file.file && file.file.data && file.file.data.full_url) {
				window.open(file.file.data.full_url);
			}
		},
		getExtension(filename) {
			var re = /(?:\.([^.]+))?$/;
			return re.exec(filename)[1];
		},
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
	},

	computed: {
		currentBrand() {
			return this.brands.filter((brand) => {
				return brand.brand_id.slug == this.brandSlug;
			});
		},
	},

	created() {
		this.brandSlug = this.$route.params.brand_slug;

		Directus.client.api
			.get("/items/pricelists", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,file.*,brand_id.*.*",
			})
			.then((response) => {
				this.pricelists = response.data;

				this.brands = _.sortBy(
					_.uniqBy(this.pricelists, "brand_id.id"),
					["brand_id.sort"]
				);
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "prijslijsten",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function () {},
	watch: {
		$route(to, from) {
			this.menu = this.$route.params.menu;
			this.brandSlug = this.$route.params.brand_slug;

			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
