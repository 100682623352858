<template>
	<layout-default>
		<div>
			<div class="uk-position-center" v-if="loading == true">
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div class="" v-if="loading == false">
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<div v-for="(promotion, index) in filteredPromotions" :key="index">
								<div v-if="(promotion.show_large == 1 && !promotionId) || promotionId == promotion.id">
									<div class="">
										<div class="p-mb-5">
											<h1>{{ promotion.title }}</h1>
										</div>
									</div>

									<Card class="p-mb-6">
										<template slot="content">
											<div>
												<div class="uk-grid">
													<div class="uk-width-expand">
														<div class="p-mb-4">
															<h2>{{ promotion.subtitle }}</h2>
														</div>
														<div class="p-mb-4">
															<div v-html="promotion.content"></div>
														</div>
														<div class="p-mb-4" v-if="promotion.image2">
															<img :src="remote_addr + promotion.image2.data.asset_url" style="max-width:300px;" />
														</div>
													</div>
													<div class="uk-width-1-2@m" v-if="promotion.image">
														<img :src="remote_addr + promotion.image.data.asset_url" class="uk-width-1-1" />
													</div>
												</div>

												<div v-if="promotion.files && promotion.files.length > 0">
													<div class="p-mb-4">
														<h3>Beschikbaar voor download:</h3>
													</div>
													<div class="uk-grid uk-grid-small p-mb-4">
														<div class="uk-width-1-1 uk-width-1-2@m uk-width-1-3@xl p-mb-3" v-for="(file, index) in promotion.files" :key="index" v-if="file.directus_files_id">
															<div style="position:relative;cursor:pointer" @click="openFile(file.directus_files_id.data.full_url)">
																<Card>
																	<template slot="content">
																		<div>
																			<div>
																				<div class="uk-grid uk-grid-small uk-flex-middle">
																					<div class="uk-width-auto">
																						<div :class="'fi fi-size-xs fi-' + getExtension(file.directus_files_id.filename_disk)">
																							<div class="fi-content">
																								{{ getExtension(file.directus_files_id.filename_disk) }}
																							</div>
																						</div>
																					</div>
																					<div class="uk-width-expand p-text-nowrap p-text-truncate" style="font-size:0.85rem;">
																						<div>
																							{{ file.directus_files_id.title }}
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</template>
																</Card>
															</div>
														</div>
													</div>
												</div>
											</div>
										</template>
									</Card>
								</div>
							</div>
						</div>
						<div class="uk-width-1-1 uk-width-1-3@l uk-width-1-4@xl p-mb-5">
							<div>
								<div class="p-mb-5 typography-main uk-position-relative">
									<h1 style="opacity:0;">-</h1>
									<h3 class="uk-position-top p-mt-4">Lopende acties:</h3>
								</div>
							</div>

							<div class="uk-grid typography-main">
								<div v-for="(promotion, index) in filteredPromotions" :key="index" class="uk-width-1-2@m uk-width-1-1@l p-mb-5">
									<Card>
										<template slot="header" v-if="promotion.image">
											<div class="uk-position-relative" v-aspect-ratio="'2:1'">
												<img
													class="uk-position-absolute"
													:src="remote_addr + promotion.image.data.asset_url + '?w=800&h=400&q=100&f=contain'"
													style="position: absolute;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;"
												/>
											</div>
										</template>
										<template slot="title">
											{{ getLanguageField(promotion, "title") }}
										</template>
										<template slot="subtitle">
											{{ getLanguageField(promotion, "subtitle") }}
										</template>
										<template slot="content">
											<div v-html="promotion.content_short"></div>
										</template>
										<template slot="footer">
											<div class="uk-grid uk-grid-small uk-flex-middle">
												<div class="uk-width-expand">
													<Button icon="pi pi-arrow-right" label="Lees meer" @click="gotoPromotion(promotion.id)" />
												</div>
												<div class="uk-width-1-4" v-if="promotion.image2">
													<img :src="remote_addr + promotion.image2.data.asset_url + '?w=400&h=400&q=100&f=contain'" class="uk-width-1-1" @click="lightBoxShow('Sigma Perfect', remote_addr + promotion.image2.data.asset_url)" />
												</div>
											</div>
										</template>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
			<Dialog :header="lightbox.title" :visible.sync="lightbox.visible" :style="{ maxWidth: '95vw' }" :modal="true">
				<div class="p-m-0">
					<img :src="lightbox.image" style="max-width:95vw;" />
				</div>
			</Dialog>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: false,
			menu: {},
			displayModal: false,
			promotionId: null,
			lightbox: {
				title: null,
				visible: false,
				image: null,
			},
			brand: {},
			brands: [],
			promotions: [],
		};
	},
	props: {},
	methods: {
		lightBoxShow(title, image) {
			this.lightbox.title = title;
			this.lightbox.image = image;
			this.lightbox.visible = true;
		},
		gotoPromotion(id) {
			this.$router.push({
				name: "promotions",
				params: {
					id: id,
				},
			});
		},
		getExtension(filename) {
			var re = /(?:\.([^.]+))?$/;
			return re.exec(filename)[1];
		},
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},

		openFile(file) {
			window.open(file);
		},
	},

	computed: {
		filteredPromotions() {
			return this.promotions.filter((promotion) => {
				return !this.brand.brand_id || !this.brand.brand_id.id || promotion.brand_id.id == this.brand.brand_id.id;
			});
		},
		currentPromotion() {
			return this.promotions.filter((promotion) => {
				return promotion.id == this.promotionId;
			});
		},
	},
	created() {
		this.promotionId = this.$route.params.id;
		this.menu = this.$route.params.menu;

		Directus.client.api
			.get("/items/promotions", {
				"filter[status][eq]": "published",
				"filter[date_show_on_website][lte]": "now",
				"filter[date_hide_from_website][gte]": "now",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,translation.*,image.*,image2.*,files.directus_files_id.*,brand_id.*",
			})
			.then((response) => {
				this.promotions = response.data;

				this.brands = _.uniqBy(this.promotions, "brand_id.id");
			});
	},
	mounted: function() {},
	watch: {
		$route(to, from) {
			this.promotionId = this.$route.params.id;
			this.menu = this.$route.params.menu;
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
