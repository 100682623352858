<template>
	<layout-login>
		<div class="ppgbackground_login">
			<div
				class="p-shadow-17 uk-position-center"
				style="width:600px;max-width:90%;"
			>
				<Card v-if="mode == 'forgotpassword' || mode == 'passwordrecoveryemailsend'">
					<template slot="header">
						<div class="t-bg-1 p-p-2">
							<div class="uk-grid uk-grid-small uk-flex-middle">
								<div class="uk-width-auto">
									<img
										src="@/assets/ppg_logo_white.svg"
										class="p-ml-1"
										style="width:50px;"
									/>
								</div>
								<div class="uk-width-auto p-text-bold t-fg-white">
									{{ $t("common.websiteName") }}
								</div>
								<div class="uk-width-expand"></div>
								<div
									class="uk-width-auto"
									v-if="1 == 2"
								>
									<LocaleSwitcher />
								</div>
							</div>
						</div>
						<div style="text-align:center;">
							<img
								src="@/assets/undraw/undraw_forgot_password_gi2d.svg"
								class=""
								style="width:150px;margin:20px;"
							/>
						</div>
					</template>
					<template slot="title">
						<div
							style="text-align:center;"
							class="p-pb-1"
						>{{ $t("account.iForgotMyPassword") }}.</div>
					</template>
					<template slot="content">
						<ValidationObserver ref="observer-forgotpassword">
							<div class="p-field p-p-3 p-text-center t-fg-1">
								<div v-if="mode == 'forgotpassword'">
									{{ $t("account.noProblemEnterEmailForPasswordRecovery") }}
								</div>
								<div v-if="mode == 'passwordrecoveryemailsend'">
									{{ $t("account.passwordRecoveryEmailSend", { email: user.email }) }}
								</div>
							</div>
							<div v-if="mode != 'passwordrecoveryemailsend'">
								<div class="p-fluid p-grid p-pl-6 p-pr-6">
									<div
										class="p-field p-col-12"
										style="margin:0px;"
									>
										<div>
											<ValidationProvider
												v-slot="v"
												rules="required|email"
												name="E-mail"
											>
												<span class="p-float-label p-input-icon-right">
													<i
														class="pi pi-exclamation-triangle"
														v-if="v.errors.length > 0"
														v-tooltip.bottom="v.errors[0]"
													/>
													<InputText
														type="email"
														v-model="user.email"
														:class="v.errors.length == 0 ? '' : 'p-invalid'"
													/>
													<label>{{ $t("account.eMail") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</div>
						</ValidationObserver>
						<Message
							v-if="loginError"
							severity="error"
						>{{ loginError }}</Message>
					</template>
					<template slot="footer">
						<div class="uk-grid uk-grid-small uk-flex-bottom">
							<div
								class="uk-width-auto@s p-mb-2"
								v-if="mode != 'passwordrecoveryemailsend'"
							>
								<Button
									class="p-button-lg"
									:icon="busy == true ? 'pi pi-spin pi-spinner' : 'pi pi-envelope'"
									:label="$t('account.send')"
									@click="sendPasswordRecoveryEmail()"
								/>
							</div>
							<div class="uk-width-expand@s uk-flex-first@s p-mb-2">
								<Button
									icon="pi pi-question-circle"
									:label="$t('account.signIn')"
									class="p-button-outlined p-button-sm"
									@click="setMode('signin')"
								/>
							</div>
						</div>
					</template>
				</Card>

				<Card v-if="mode == 'resetpassword'">
					<template slot="header">
						<div class="t-bg-1 p-p-2">
							<div class="uk-grid uk-grid-small uk-flex-middle">
								<div class="uk-width-auto">
									<img
										src="@/assets/ppg_logo_white.svg"
										class="p-ml-1"
										style="width:50px;"
									/>
								</div>
								<div class="uk-width-auto p-text-bold t-fg-white">
									{{ $t("common.websiteName") }}
								</div>
								<div class="uk-width-expand"></div>
								<div
									class="uk-width-auto"
									v-if="1 == 2"
								>
									<LocaleSwitcher />
								</div>
							</div>
						</div>
						<div style="text-align:center;">
							<img
								src="@/assets/undraw/undraw_Access_account_re_8spm.svg"
								class=""
								style="width:150px;margin:20px;"
							/>
						</div>
					</template>
					<template slot="title">
						<div
							style="text-align:center;"
							class="p-mb-6"
						>
							{{ $t("account.chooseNewPassword") }}
						</div>
					</template>
					<template slot="content">
						<ValidationObserver ref="observer-resetpassword">
							<div>
								<div class="p-fluid p-grid p-pl-6 p-pr-6">
									<div
										class="p-field p-col-12"
										style="margin:0px;"
									>
										<div>
											<ValidationProvider
												v-slot="v"
												rules="required|min:6|max:18"
												:name="$t('account.password')"
											>
												<span class="p-float-label p-input-icon-right">
													<i
														class="pi pi-exclamation-triangle"
														v-if="v.errors.length > 0"
														v-tooltip.bottom="v.errors[0]"
													/>
													<InputText
														v-model="user.password"
														type="password"
														:class="v.errors.length == 0 ? '' : 'p-invalid'"
													/>
													<label>{{ $t("account.password") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
									<div
										class="p-field p-col-12"
										style="margin:0px;"
									>
										<div>
											<ValidationProvider
												v-slot="v"
												:rules="'required|is:' + user.password"
												:name="$t('account.password')"
											>
												<span class="p-float-label p-input-icon-right">
													<i
														class="pi pi-exclamation-triangle"
														v-if="v.errors.length > 0"
														v-tooltip.bottom="v.errors[0]"
													/>
													<InputText
														v-model="user.password2"
														type="password"
														:class="v.errors.length == 0 ? '' : 'p-invalid'"
													/>
													<label>{{ $t("account.repeat") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</div>
						</ValidationObserver>
						<Message
							v-if="loginError"
							severity="error"
						>{{ loginError }}</Message>
					</template>
					<template slot="footer">
						<div class="uk-grid uk-grid-small uk-flex-bottom">
							<div class="uk-width-auto@s p-mb-2">
								<Button
									class="p-button-lg"
									:icon="busy == true ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
									:label="$t('account.save')"
									@click="setNewPassword()"
								/>
							</div>
							<div class="uk-width-expand@s uk-flex-first@s p-mb-2"></div>
						</div>
					</template>
				</Card>

				<Card v-if="mode == 'signin'">
					<template slot="header">
						<div class="t-bg-1 p-p-2">
							<div class="uk-grid uk-grid-small uk-flex-middle">
								<div class="uk-width-auto">
									<img
										src="@/assets/ppg_logo_white.svg"
										class="p-ml-1"
										style="width:50px;"
									/>
								</div>
								<div class="uk-width-auto p-text-bold t-fg-white">
									{{ $t("common.websiteName") }}
								</div>
								<div class="uk-width-expand"></div>
								<div
									class="uk-width-auto"
									v-if="1 == 2"
								>
									<LocaleSwitcher />
								</div>
							</div>
						</div>
						<div style="text-align:center;">
							<img
								src="@/assets/undraw/undraw_Access_account_re_8spm.svg"
								class=""
								style="width:150px;margin:20px;"
							/>
						</div>
					</template>
					<template slot="title">
						<div
							style="text-align:center;"
							class="p-pb-1"
						>
							{{ $t("account.signIn") }}
						</div>
					</template>
					<template slot="content">
						<ValidationObserver ref="observer-login">
							<div>
								<div class="p-fluid p-grid p-pl-6 p-pr-6">
									<div class="p-field p-col-12">
										<div>
											<ValidationProvider
												v-slot="v"
												rules="required|email"
												name="E-mail"
											>
												<span class="p-float-label p-input-icon-right">
													<i
														class="pi pi-exclamation-triangle"
														v-if="v.errors.length > 0"
														v-tooltip.bottom="v.errors[0]"
													/>
													<InputText
														type="email"
														v-model="user.email"
														:class="v.errors.length == 0 ? '' : 'p-invalid'"
													/>
													<label>{{ $t("account.eMail") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
									<div class="p-field p-col-12">
										<div>
											<ValidationProvider
												v-slot="v"
												rules="required"
												:name="$t('account.password')"
											>
												<span class="p-float-label p-input-icon-right">
													<i
														class="pi pi-exclamation-triangle"
														v-if="v.errors.length > 0"
														v-tooltip.bottom="v.errors[0]"
													/>
													<InputText
														v-model="user.password"
														type="password"
														:class="v.errors.length == 0 ? '' : 'p-invalid'"
													/>
													<label>{{ $t("account.password") }}</label>
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</div>
						</ValidationObserver>
						<Message
							v-if="loginError"
							severity="error"
						>{{ loginError }}</Message>
					</template>
					<template slot="footer">
						<div class="p-fluid p-grid p-pl-6 p-pr-6">
							<div
								class="p-field p-col-12"
								style="margin:0px;"
							>
								<div class="uk-grid uk-grid-small uk-flex-bottom">
									<div class="uk-width-expand@s"></div>
									<div class="uk-width-auto@s p-mb-2">
										<Button
											class="p-button-lg"
											:icon="busy == true ? 'pi pi-spin pi-spinner' : 'pi pi-sign-in'"
											:label="$t('account.signIn')"
											@click="signIn()"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="t-fg-2 p-text-center">
							<div>
								<Button
									:label="$t('account.iForgotMyPassword')"
									class="p-button p-button-text p-button-sm"
									@click="setMode('forgotpassword')"
								/>
							</div>
							<div style="opacity:0.5;">
								<small>Wilt u toegang tot het PPG Retail portaal of kunt u niet inloggen? Klik <a
										href="mailto:ppgretailnl@ppg.com?subject=Vraag over inloggen op PPG Retail"
										class="t-fg-2"
									>hier.</a></small>
							</div>
						</div>
					</template>
				</Card>
			</div>

			<Toast />
			<cookie-law theme="base">
				<div slot-scope="props">
					<div class="uk-grid">
						<div class="uk-width-1-1 uk-width-expand@m">
							Deze site maakt gebruik van cookies. Tijdens je bezoek worden deze opgeslagen op het apparaat waarmee je onze website bezoekt. Lees onze <router-link to="cookies">cookiepolicy</router-link> als je wilt weten hoe wij daar mee omgaan.
						</div>
						<div class="uk-width-1-1 uk-width-auto@m">
							<Button @click="props.accept">OK</Button>
						</div>
					</div>
				</div>
			</cookie-law>
		</div>
	</layout-login>
</template>

<script>
const axios = require("axios");
const parseString = require("xml2js").parseString;

import CookieLaw from "vue-cookie-law";

import LayoutLogin from "@/layout/Login.vue";
import Directus from "@/services/directus/";

import LocaleSwitcher from "@/components/i18n/LocaleSwitcher";

import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Message from "primevue/message";

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

export default {
	components: {
		CookieLaw,
		LayoutLogin,
		Directus,

		LocaleSwitcher,

		Card,
		Button,
		InputText,
		Toast,
		Message,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			user: {
				email: "",
				password: "",
				password2: "",
			},
			mode: "",
			loginError: null,
			busy: false,
		};
	},
	props: {
		nextUrl: String,
	},
	methods: {
		setMode(mode) {
			this.mode = mode;
		},
		signIn() {
			let self = this;
			let observer = self.$refs["observer-login"];
			//console.log(observer);
			if (observer.flags.valid == true) {
				Directus.signIn(self.user.email, self.user.password).then(
					(response) => {
						//console.log("authresponse", response);

						if (!response.error) {
							self.$cookie.set(
								"auth",
								JSON.stringify(response),
								{
									expires: "1d",
								},
								"/"
							);
							location.href = "/";
						} else {
							self.$toast.add({
								severity: "error",
								summary: "Sign in",
								detail: response.error.message,
								life: 6000,
							});
						}
					}
				);
			}
		},
		sendPasswordRecoveryEmail() {
			let self = this;
			let observer = self.$refs["observer-forgotpassword"];
			//console.log(observer);
			if (observer.flags.valid == true) {
				Directus.sendPasswordRecoveryEmail(
					self.user.email,
					process.env.VUE_APP_REMOTE_ADDR + "/resetpassword"
				).then((response) => {
					console.log("authresponse", response);

					if (!response.data.error) {
						self.setMode("passwordrecoveryemailsend");
					} else {
						self.$toast.add({
							severity: "error",
							summary: "Password reset",
							detail: response.data.error.message,
							life: 6000,
						});
					}
				});
			}
		},
		setNewPassword() {
			let self = this;
			let observer = self.$refs["observer-resetpassword"];
			//console.log(observer);
			if (observer.flags.valid == true) {
				let token = self.$route.query.token;
				Directus.setNewPassword(self.user.password, token).then(
					(response) => {
						if (!response.data.error) {
							self.setMode("signin");
						} else {
							self.$toast.add({
								severity: "error",
								summary: "Password reset",
								detail: response.data.error.message,
								life: 6000,
							});
						}
					}
				);
			}
		},
	},
	mounted: function () {
		//console.log(this.$router.currentRoute.path);
		if (this.$router.currentRoute.name == "resetpassword") {
			this.mode = "resetpassword";
		} else {
			this.mode = "signin";
		}
	},
	created() {
		this.user.email = this.$route.params.email;
		this.user.password = this.$route.params.password;
	},
};
</script>

<style scoped></style>
