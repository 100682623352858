<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">

						<div class="uk-width-1-1 p-pb-3">
							<img
								class="uk-width-1-3 uk-width-1-4@s uk-width-1-5@m"
								style="float:right;max-width:300px;"
								src="/images/social/facebookinstagram3d.png"
							>
							<div v-html="content[0].html"></div>
						</div>
					</div>
					<div
						class="typography-main"
						v-for="(campaign,i) in campaigns"
						:key="i"
					>
						<div class="p-pb-3">
							<h2>{{campaign.name}}</h2>
						</div>
						<div class="uk-grid typography-main">
							<div
								class="uk-width-1-2 uk-width-1-3@s uk-width-1-4@m p-mb-6"
								v-for="(add, index) in socialadsByCampaign(campaign)"
								:key="index"
							>
								<Card style="padding:none;cursor:pointer;">
									<template slot="content">
										<div
											class="uk-position-relative"
											v-aspect-ratio="'1:1'"
											v-if="add && add.image && add.image.data"
											@click="downloadAd(remote_addr + add.image.data.asset_url)"
										>
											<img
												class="uk-position-absolute"
												:src="remote_addr + add.image.data.asset_url + '?w=800&h=800&q=100&f=contain'"
												style="position: absolute; padding:20px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;

													"
											/>
										</div>
										<div
											class="uk-position-relative"
											v-aspect-ratio="'1:1'"
											v-else
										>
											<div
												class="uk-position-absolute"
												style="position: absolute; padding:20px; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; cursor:pointer;"
											>{{ add.name }}</div>
										</div>
									</template>

									<template slot="footer">
										<div @click="downloadAd(remote_addr + add.image.data.asset_url)">
											<img
												v-if="add.type=='facebook'"
												v-tooltip.top="'Facebook'"
												src="/images/social/facebook.webp"
												class="p-mr-2"
												style="width:32px;"
												@click="openUrl(brand.url_facebook)"
											/>
											<img
												v-if="add.type=='instagram'"
												v-tooltip.top="'Instagram'"
												src="/images/social/instagram.webp"
												class="p-mr-2"
												style="width:32px;"
												@click="openUrl(brand.url_instagram)"
											/>
											<h3>{{add.name}}</h3>
										</div>

									</template>
								</Card>
							</div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			campaigns: [],
			socialads: [],
			content: [{}],
		};
	},
	props: {},
	methods: {
		openUrl(url) {
			if (url) {
				window.open(url);
			}
		},
		socialadsByCampaign(campaign) {
			let self = this;
			let socialads = [];
			console.log(campaign);
			if (self.socialads && campaign && campaign.id) {
				socialads = _.filter(self.socialads, function (o) {
					console.log(o);
					if (o.campaign) {
						return o.campaign.id == campaign.id;
					}
				});
			}
			return socialads;
		},
		downloadAd(source) {
			const fileName = source.split("/").pop();
			var el = document.createElement("a");
			el.setAttribute("href", source);
			el.setAttribute("download", fileName);
			el.setAttribute("target", "_blank");
			document.body.appendChild(el);
			el.click();
			el.remove();
		},
	},
	computed: {},
	created() {
		Directus.client.api
			.get("/items/campaigns", {
				"filter[status][eq]": "published",
				fields: "*",
				order: "sort",
			})
			.then((response) => {
				this.campaigns = response.data;
				console.log(this.campaigns);
			});
		Directus.client.api
			.get("/items/socialads", {
				"filter[status][eq]": "published",
				fields: "*,image.*",
				order: "sort",
			})
			.then((response) => {
				this.socialads = response.data;
				console.log(this.socialads);
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "social-ads",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
