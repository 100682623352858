<template>
	<div
		class="mainMenuBar"
		id="mainMenuBar"
		:class="{ sticky: stickyNavActive }"
	>
		<Menubar :model="items">
			<template #start>
				<div class="uk-grid uk-grid-small uk-flex-middle">
					<div class="uk-width-auto">
						<a href="/"><img
								src="@/assets/ppg_logo_white.svg"
								class="p-ml-1"
								style="width:50px;"
							/></a>
					</div>
					<div class="uk-width-auto p-text-bold t-fg-white p-mr-2">
						<a
							href="/"
							style="text-decoration:none;"
							class="t-fg-white"
						>{{ $t("common.websiteName") }}</a>
					</div>
				</div>
			</template>

			<template #end>
				<div class="uk-hidden">
					<LocaleSwitcher />
				</div>
				<div class="p-d-inline-block">
					<Button
						:label="user.first_name"
						class="p-button-sm"
						icon="pi pi-user"
						@click="$refs.userMenu.toggle($event)"
					></Button>
					<Menu
						ref="userMenu"
						:model="itemsUser"
						:popup="true"
					/>
				</div>
			</template>
		</Menubar>
	</div>
</template>

<script>
import Directus from "@/services/directus/";
import LocaleSwitcher from "@/components/i18n/LocaleSwitcher";

import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import Button from "primevue/button";

export default {
	name: "NavBar",
	components: {
		Directus,
		LocaleSwitcher,

		Menubar,
		Menu,
		Button,
	},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
	},
	data() {
		return {
			stickyNavActive: false,
			user: {},
			items: [],
		};
	},
	mounted() {
		window.document.onscroll = () => {
			let currentScroll =
				document.documentElement.scrollTop || document.body.scrollTop;
			let navBar = document.getElementById("mainMenuBar");
			if (window.scrollY > navBar.offsetTop + navBar.offsetHeight) {
				if (this.lastScroll > currentScroll) {
					this.stickyNavActive = true;
				} else {
					this.stickyNavActive = false;
				}
			} else {
				this.stickyNavActive = false;
			}
			this.lastScroll = currentScroll;
		};
	},
	created() {
		let self = this;
		self.user = self.$route.params.user;

		self.menu = [];
		self.items = [];
		Directus.client.api
			.get("/items/menu", {
				"filter[status][eq]": "published",
				"filter[type][eq]": "Main",
				fields: "*,translation.*",
			})
			.then((response) => {
				self.menu = response.data;

				self.items = Directus.hierarchy(self.menu, this.$i18n.locale);
				console.log(self.menu);

				setTimeout(function () {
					const menubars = self.$el.querySelectorAll(
						".p-menubar-root-list"
					);
					menubars.forEach((menubar) => {
						//console.log(menubar);
						const menuitems = menubar.querySelectorAll(
							":scope > .p-menuitem > .p-menuitem-link"
						);
						menuitems.forEach((menuitem) => {
							//.console.log("y", menuitem);
							menuitem.addEventListener("mouseover", function () {
								const activemenuitems =
									menubar.querySelectorAll(
										".p-menuitem-active"
									);
								activemenuitems.forEach((activemenuitem) => {
									activemenuitem.classList.remove(
										"p-menuitem-active"
									);
								});
								//console.log(menuitem.parentElement);
								if (
									menuitem.getAttribute("aria-haspopup") ==
									"true"
								) {
									menuitem.parentElement.classList.add(
										"p-menuitem-active"
									);
									//console.log(menuitem.parentElement);
								}
							});
						});
					});
				}, 100);
			});

		self.itemsUser = [
			{
				label: "Mijn account",
				to: { path: "/account/" },
			},
			{
				label: "Sign out",
				command: (event) => {
					Directus.signOut().then((response) => {
						console.log(response);
						self.$cookie.delete("auth");
						location.href = "/";
					});
				},
			},
		];
	},

	watch: {
		$route(to, from) {
			this.promotionId = this.$route.params.id;
			this.items = Directus.hierarchy(this.menu, this.$i18n.locale);
			window.scrollTo(0, 0);
		},
	},
};
</script>

<style lang="scss"></style>
