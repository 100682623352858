<template>
	<div class="LayoutDefault">
		<NavBar />

		<div>
			<div>
				<slot />
			</div>
		</div>

		<div class="uk-width-1-1 t-bg-1 t-fg-white footer">
			<div class="uk-container uk-container-large">
				<div class="uk-grid">
					<div class="uk-width-expand@l">
						<div class="p-pt-3 p-pb-3">&copy; PPG Coatings Nederland B.V.</div>
					</div>
					<div class="uk-width-auto@l">
						<NavBarFooter />
					</div>
				</div>
				<div class="">
					<div class="uk-grid">
						<div class="uk-width-1-4 uk-width-1-5@s uk-width-1-6@m uk-width-expand@l p-mb-4" v-for="(brand, index) in brands" :key="index">
							<Card v-if="brand.url">
								<template slot="content">
									<div class="uk-position-relative" v-aspect-ratio="'1:1'" v-if="brand && brand.logo && brand.logo.data">
										<img
											class="uk-position-absolute"
											:src="remote_addr + brand.logo.data.asset_url + '?w=800&h=400&q=100&f=contain'"
											style="position: absolute; padding:0px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;
													cursor:pointer !important;
													"
											@click="openUrl(brand.url)"
										/>
									</div>
									<div class="uk-position-relative" v-aspect-ratio="'1:1'" v-else>
										<div class="uk-position-absolute" style="position: absolute; padding:20px; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; cursor:pointer;">{{ brand.name }}</div>
									</div>
								</template>
							</Card>
						</div>
					</div>
				</div>
				<div class="p-pt-3 p-pb-3">
					<div class="uk-grid p-is">
						<div class="uk-width-expand@l" style="font-size:0.85em !important;">Kopraweg 35, 1047 BP Amsterdam, Nederland | <a href="https://www.ppg.com" style="font-size:1em !important;" class="t-fg-white">www.ppg.com</a></div>
						<div class="uk-width-auto@l" style="font-size:0.85em !important;">Registered at the Chamber of Commerce Amsterdam no. 33130510</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/layout/NavBar";
import NavBarFooter from "@/components/layout/NavBar_Footer";

import Directus from "@/services/directus/";

import Card from "primevue/card";
import Button from "primevue/button";

export default {
	components: {
		NavBar,
		NavBarFooter,

		Directus,

		Card,
		Button,
	},

	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			brands: [],
		};
	},
	mounted() {
		Directus.client.api
			.get("/items/brands", {
				"filter[status][eq]": "published",
				"filter[url][nempty]": "",
				"fields": "*,logo.*",
			})
			.then((response) => {
				this.brands = response.data;
				console.log(this.brands);
			});
	},
	methods: {
		openUrl(url) {
			if (url) {
				window.open(url);
			}
		},
	},
};
</script>

<style scoped></style>
