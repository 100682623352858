<template>
	<div class="footerMenuBar" id="footerMenuBar">
		<Menubar :model="items">
			<template #start></template>

			<template #end></template>
		</Menubar>
	</div>
</template>

<script>
import Directus from "@/services/directus/";

import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import Button from "primevue/button";

export default {
	name: "NavBar",
	components: {
		Directus,

		Menubar,
		Menu,
		Button,
	},
	methods: {
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
	},
	data() {
		return {
			stickyNavActive: false,
			user: {},
			items: [],
		};
	},
	mounted() {},
	created() {
		this.menu = [];
		this.items = [];
		Directus.client.api
			.get("/items/menu", {
				"filter[status][eq]": "published",
				"filter[type][eq]": "Footer",
				fields: "*,translation.*",
			})
			.then((response) => {
				this.menu = response.data;

				this.items = Directus.hierarchy(this.menu, this.$i18n.locale);
			});
	},

	watch: {
		$route(to, from) {
			this.items = Directus.hierarchy(this.menu, this.$i18n.locale);
		},
	},
};
</script>

<style lang="scss"></style>
