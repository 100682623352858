<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="typography-main">
						<div class="uk-width-expand p-pb-3">
							<div v-html="content[0].html"></div>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
		};
	},
	props: {},
	methods: {},
	computed: {},
	created() {
		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "kleurpresentatie",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
