<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">

						<div class="uk-width-1-1 p-pb-3">

							<div v-html="content[0].html"></div>

						</div>

						<div>
							<button
								class="p-button"
								@click="gotoClickAndOrderStore()"
							><i class="pi pi-external-link p-mr-2"></i> Naar de bestel portal...</button>

						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

import axios from "axios";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			campaigns: [],
			socialads: [],
			content: [{}],
		};
	},
	props: {},
	methods: {
		async gotoClickAndOrderStore() {
			let self = this;

			if (self.user && self.user.email) {
				axios({
					method: "GET",
					url: "https://www.ppg-retail.nl/clickandorderstore/",
					headers: {
						"Content-Type": "application/x-www-form- urlencoded",
					},
					params: {
						email: self.user.email,
					},
					data: null,
				}).then((response) => {
					if (response && response.data && response.data) {
						//console.log("hoera", response.data.hash);
						window.open(
							"https://ppg-retail-nl.clickandorderstore.nl/autologin/retailnl/?hash=" +
								response.data.hash +
								"&email=" +
								self.user.email +
								"&user_name_first=" +
								self.user.first_name +
								"&user_name_last=" +
								self.user.last_name +
								"&user_id=" +
								self.user.id +
								"&portal=retailnl"
						);
					} else {
						return {};
					}
				});
			}
		},
	},
	computed: {
		user() {
			let self = this;
			return self.$route.params.user;
		},
	},
	created() {
		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "flyers",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
