<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand@s">
							<h1>Kleurklacht formulier</h1>
							<div
								class="uk-width-1-1 p-pb-3"
								v-if="content"
							>
								<div v-html="content[0].html"></div>
							</div>
						</div>
						<div class="uk-width-1-2@m uk-width-1-3@l uk-width-1-4@xl p-mb-3">
							<Card>
								<template slot="content">
									<div class="eqheight p-mb-3">
										<h3 class="p-mb-2">Afdeling Technisch Centrum</h3>
										<div class="p-mb-2"><i class="pi pi-mobile p-mr-2"></i> <a :href="'tel:+31 (0)297 54 1234'">+31 (0)297 54 1234</a></div>
										<div class="p-mb-2"><i class="pi pi-print p-mr-2"></i> +31 (0)297 54 1246</div>
										<div class="p-mb-2"><i class="pi pi-envelope p-mr-2"></i> <a :href="'mailto:technicalsupportBNL@ppg.com'">technicalsupportBNL@ppg.com</a></div>
									</div>
								</template>
							</Card>
						</div>
					</div>

					<div v-if="formsend == true">
						<div class="p-mb-5 typography-main">
							<div v-html="content2[0].html"></div>
						</div>
					</div>

					<div v-if="formsend != true">
						<div class="typography-main">
							<ValidationObserver ref="observer2">
								<div>
									<div class="p-fluid p-grid">
										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>Klantgegevens</h3>
										</div>
										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Contactpersoon"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Contactpersoon"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Contactpersoon</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required|email"
													name="E-mail"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="email"
															v-model="form.Email"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>E-mail</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Vestiginsplaats"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Vestiginsplaats"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Vestigingsplaats</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Adres"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Adres"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Straat + huisnr.</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Telefoon"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Telefoon"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Telefoon</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Vertegenwoordiger"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Vertegenwoordiger"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Vertegenwoordiger</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>Artikelgegevens</h3>
										</div>

										<div class="p-field p-col-12 p-md-3">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Artikelnummer"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.artikelnummer"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Artikelnummer</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-3">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Merk"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Merk"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Merk</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-8 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Artikelnaam"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Artikelnaam"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Artikelnaam</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-4 p-md-2">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Inhoudsmaat"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Inhoudsmaat"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Inhoudsmaat</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Partijnummer"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Partijnummer"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Partijnummer</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Volgnummer"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Volgnummer"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Volgnummer</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Basis"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Basis"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Basis</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>Kleurgegevens</h3>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Waaier"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Waaier"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Waaier</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-6">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Kleurnaam"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Kleurnaam"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Kleurnaam</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Aantal"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Aantal"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Aantal</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Eenheid"
												>
													<Dropdown
														v-model="form.Eenheid"
														:options="units"
														optionLabel="label"
														optionValue="value"
													/>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Aantal"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<Calendar
															v-model="form.Receptdatum"
															selectionMode="single"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Receptdatum</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>Kleurafwijking</h3>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>Is de kleur NAT beoordeeld?</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="BeoordelingNat"
													>
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingNat1"
																name="BeoordelingNat"
																value="Ja"
																v-model="form.BeoordelingNat"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingNat1">Ja</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingNat2"
																name="BeoordelingNat"
																value="Nee"
																v-model="form.BeoordelingNat"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingNat2">Nee</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingNat3"
																name="BeoordelingNat"
																value="Weet niet"
																v-model="form.BeoordelingNat"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingNat3">Weet niet</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>Is de kleur DROOG beoordeeld?</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="BeoordelingDroog"
													>
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingDroog1"
																name="BeoordelingDroog"
																value="Ja"
																v-model="form.BeoordelingDroog"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingDroog1">Ja</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingDroog2"
																name="BeoordelingDroog"
																value="Nee"
																v-model="form.BeoordelingDroog"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingDroog2">Nee</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="BeoordelingDroog3"
																name="BeoordelingDroog"
																value="Weet niet"
																v-model="form.BeoordelingDroog"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="BeoordelingDroog3">Weet niet</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>Zijn de canisters met de juiste kleurpasta gevuld?</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="JuisteKleurpasta"
													>
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="JuisteKleurpasta1"
																name="JuisteKleurpasta"
																value="Ja"
																v-model="form.JuisteKleurpasta"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="JuisteKleurpasta1">Ja</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="JuisteKleurpasta2"
																name="JuisteKleurpasta"
																value="Nee"
																v-model="form.JuisteKleurpasta"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="JuisteKleurpasta2">Nee</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="JuisteKleurpasta3"
																name="JuisteKleurpasta"
																value="Weet niet"
																v-model="form.JuisteKleurpasta"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="JuisteKleurpasta3">Weet niet</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>Zijn alle kleurpasta’s gedoseerd?</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="KleurpastaGedoseerd"
													>
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="KleurpastaGedoseerd1"
																name="KleurpastaGedoseerd"
																value="Ja"
																v-model="form.KleurpastaGedoseerd"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="KleurpastaGedoseerd1">Ja</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="KleurpastaGedoseerd2"
																name="KleurpastaGedoseerd"
																value="Nee"
																v-model="form.KleurpastaGedoseerd"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="KleurpastaGedoseerd2">Nee</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="KleurpastaGedoseerd3"
																name="KleurpastaGedoseerd"
																value="Weet niet"
																v-model="form.KleurpastaGedoseerd"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="KleurpastaGedoseerd3">Weet niet</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-expand@m p-mb-3">
													<strong>Is de doseeropening schoon?</strong>
												</div>

												<div class="uk-width-auto@m p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="DoseeropeningSchoon"
													>
														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="DoseeropeningSchoon1"
																name="DoseeropeningSchoon"
																value="Ja"
																v-model="form.DoseeropeningSchoon"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="DoseeropeningSchoon1">Ja</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="DoseeropeningSchoon2"
																name="DoseeropeningSchoon"
																value="Nee"
																v-model="form.DoseeropeningSchoon"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="DoseeropeningSchoon2">Nee</label>
														</div>

														<div class="p-field-radiobutton p-d-inline p-mr-4">
															<RadioButton
																id="DoseeropeningSchoon3"
																name="DoseeropeningSchoon"
																value="Weet niet"
																v-model="form.DoseeropeningSchoon"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="DoseeropeningSchoon3">Weet niet</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-1-1 p-mb-3">
													<strong>Beoordeel altijd bij (kunstmatig) daglicht! Er is afwijking ten opzichte van:</strong>
												</div>

												<div class="uk-width-1-1 p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Afwijking"
													>
														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking1"
																name="Afwijking"
																value="Waaier"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking1">Waaier</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking2"
																name="Afwijking"
																value="Folder"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking2">Folder</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking3"
																name="Afwijking"
																value="Staal"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking3">Staal</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking4"
																name="Afwijking"
																value="Deksel"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking4">Deksel</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking5"
																name="Afwijking"
																value="Sticker"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking5">Sticker</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking6"
																name="Afwijking"
																value="RM blik"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking6">RM blik</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking7"
																name="Afwijking"
																value="Eerder gemengd"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking7">Eerder gemengd</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Afwijking8"
																name="Afwijking"
																value="Bestaand werk"
																v-model="form.Afwijking"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Afwijking8">Bestaand werk</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-col-12 p-md-12 t-fg-1">
											<h3>Kleurrecept</h3>
										</div>

										<div class="p-col-12">
											<div class="uk-grid">
												<div class="uk-width-1-1 p-mb-3">
													<ValidationProvider
														v-slot="v"
														rules="required"
														name="Kleurrecept"
													>
														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept1"
																name="Kleurrecept"
																value="Te licht"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept1">Te licht</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept2"
																name="Kleurrecept"
																value="Te donker"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept2">Te donker</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept3"
																name="Kleurrecept"
																value="Te rood"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept3">Te rood</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept4"
																name="Kleurrecept"
																value="Te geel"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept4">Te geel</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept5"
																name="Kleurrecept"
																value="Te groen"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept5">Te groen</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept6"
																name="Kleurrecept"
																value="Te blauw"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept6">Te blauw</label>
														</div>

														<div class="p-field-checkbox p-d-inline p-mr-4">
															<Checkbox
																id="Kleurrecept7"
																name="Kleurrecept"
																value="Te grijs"
																v-model="form.Kleurrecept"
																:class="v.errors.length == 0 ? '' : 'p-invalid'"
															/>
															<label for="Kleurrecept7">Te grijs</label>
														</div>
													</ValidationProvider>
												</div>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="Pasta"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Pasta"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Pasta</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules="required"
													name="AantalShots"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="number"
															v-model="form.AantalShots"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Aantal shots</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="Pasta"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Pasta2"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Pasta</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="AantalShots"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="number"
															v-model="form.AantalShots2"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Aantal shots</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="Pasta"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Pasta3"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Pasta</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="AantalShots"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="number"
															v-model="form.AantalShots3"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Aantal shots</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-8">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="Pasta"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="text"
															v-model="form.Pasta4"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Pasta</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12 p-md-4">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="AantalShots"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<InputText
															type="number"
															v-model="form.AantalShots4"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Aantal shots</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12">
											<div class="p-mb-2">
												<h3>Duidelijke foto van de kleur/ afwijkende kleur</h3>
											</div>

											<FileUpload
												name="files[]"
												ref="fileUploadFotos"
												@upload="onUpload"
												@uploader="targafilesUpload"
												:multiple="true"
												accept="image/jpg,image/jpeg,image/png,image/bmp,image/tif,image/tiff,application/pdf"
												:maxFileSize="30000000"
												chooseLabel="Selecteer"
												uploadLabel="Upload"
												cancelLabel="Annuleer"
												:customUpload="true"
											>
												<template #empty>
													<p>Sleep je foto's hierheen of klik op 'Selecteer'.</p>
												</template>
											</FileUpload>
										</div>

										<div class="p-field p-col-12">
											<div>
												<ValidationProvider
													v-slot="v"
													rules=""
													name="Opmerkingen"
												>
													<span class="p-float-label p-input-icon-right">
														<i
															class="pi pi-exclamation-triangle"
															v-if="v.errors.length > 0"
															v-tooltip.bottom="v.errors[0]"
														/>
														<Textarea
															v-model="form.Opmerkingen"
															:autoResize="true"
															:class="v.errors.length == 0 ? '' : 'p-invalid'"
														/>
														<label>Opmerkingen</label>
													</span>
												</ValidationProvider>
											</div>
										</div>

										<div class="p-field p-col-12">
											<Button
												label="Verstuur"
												icon="pi pi-send"
												class="p-button-lg"
												style="width:auto;"
												@click="submitForm()"
											/>
										</div>
									</div>
								</div>
							</ValidationObserver>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";

import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Textarea,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		Calendar,
		RadioButton,
		Checkbox,
		FileUpload,
	},
	data() {
		return {
			loading: false,
			form: {
				Eenheid: "Ltr",
				files: [],
			},
			formsend: false,
			files: [],
			units: [
				{ value: "Ltr", label: "Liter" },
				{ value: "Kg", label: "Kilogram" },
			],
			settings: [{}],
			content: [{}],
			content2: [{}],
		};
	},
	props: {},
	methods: {
		onUpload() {},

		targafilesUpload(e) {
			let self = this;
			console.log(e);

			Axios.post(self.settings[0].url_targafiles + "/Auth/token", {
				apiKey: self.settings[0].apikey_targafiles,
				scope: "read write",
			})
				.then((response) => {
					let token = response.data.access_token;
					let URL =
						self.settings[0].url_targafiles +
						"/Files/" +
						self.settings[0].project_targafiles +
						"/kleurklachten/" +
						JSON.parse(self.$cookie.get("auth")).data.user.email +
						"?token=" +
						token;

					let config = {
						header: {
							"Content-Type": "multipart/form-data",
						},
					};
					if (e && e.files) {
						e.files.forEach((file, index) => {
							let data = new FormData();
							data.append("file", file);
							console.log(file);

							Axios.post(URL, data, config)
								.then((response) => {
									console.log("response", response);
									self.form.files.push(response.data.url);
									if (index == e.files.length - 1) {
										console.log(self.form.files);

										self.submitForm2();
									}
								})
								.catch((error) => {
									console.log("error", error);
								});
						});
					}
				})
				.catch((error) => {
					console.log("error", error);
				});
		},
		submitForm() {
			let self = this;
			let observer = self.$refs["observer2"];
			if (observer.flags.valid == true) {
				if (
					self.$refs["fileUploadFotos"] &&
					self.$refs["fileUploadFotos"].files &&
					self.$refs["fileUploadFotos"].files.length > 0
				) {
					self.$refs["fileUploadFotos"].upload();
				} else {
					self.submitForm2();
				}
			}
		},
		submitForm2() {
			let self = this;
			let body = "";
			Object.keys(self.form).forEach((key) => {
				if (key != "files") {
					body =
						body + "<b>" + key + "</b>: " + self.form[key] + "<br>";
				}
			});

			if (self.form.files && self.form.files.length > 0) {
				body = body + "<br><b>Files</b>:<br>";
				self.form.files.forEach((url) => {
					body = body + encodeURI(url) + "<br><br>";
				});
			}
			body = body + "<br><b>User (logged in)</b>:<br>";
			body = body + "<b>id</b>: " + self.user.id + "<br>";
			body = body + "<b>email</b>: " + self.user.email + "<br>";
			body =
				body + "<b>customer_id</b>: " + self.user.customer_id + "<br>";
			body = body + "<b>company</b>: " + self.user.company + "<br>";
			body = body + "<b>first_name</b>: " + self.user.first_name + "<br>";
			body = body + "<b>last_name</b>: " + self.user.last_name + "<br>";

			console.log(body);

			Directus.client2.api
				.post("/mail", {
					to: ["AMS-MB.ColourServices@ppg.com", "dev@targateam.nl"],
					from: "noreply@ppgretailnl.com",
					subject: "Kleurklacht via ppg-retail.nl",
					body: body,
					type: "html",
					data: {},
				})
				.then((response) => {
					self.formsend = true;
				});
		},
	},
	created() {
		let self = this;

		self.user = self.$route.params.user;

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "kleurklacht",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});

		Directus.client.api
			.get("/items/settings", {
				"filter[status][eq]": "published",
				//"filter[translation.language][eq]": "en-US",
				fields: "*,wholesaler_xls.*",
			})
			.then((response) => {
				this.settings = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "kleurklacht-bedankt",
				fields: "*",
			})
			.then((response) => {
				this.content2 = response.data;
			});
	},
};
</script>

<style lang="scss">
.p-fileupload .p-fileupload-buttonbar button {
	display: none;
}
</style>
