<template>
	<layout-default>
		<div v-if="users">
			<DataTable :value="users" dataKey="id" :filters="filters" class="p-datatable-sm" :autoLayout="true" :expandedRows.sync="expandedRows" @row-expand="onRowExpand" @row-collapse="onRowCollapse">
				<Column :expander="true" headerStyle="width: 3rem" />

				<Column field="id" header="ID" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.id }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['id']" class="p-column-filter" style="width:100px;" />
					</template>
				</Column>
				<Column field="role.name" header="Role" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.role.name }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['role.name']" class="p-column-filter" style="width:140px;" />
					</template>
				</Column>
				<Column field="customer_id" header="Customer" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.customer_id }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['customer_id']" class="p-column-filter" style="width:100px;" />
					</template>
				</Column>
				<Column field="status" header="Status" :sortable="true" filterMatchMode="equals">
					<template #body="slotProps">
						<i class="pi pi-circle-on" :class="slotProps.data.status == 'active' ? 't-fg-3' : 't-fg-2'" style="fontSize: 1.25em"></i>
					</template>
					<template #filter>
						<Dropdown v-model="filters['status']" :options="statuses" optionLabel="label" optionValue="value" />
					</template>
				</Column>
				<Column field="first_name" header="First name" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.first_name }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['first_name']" class="p-column-filter" />
					</template>
				</Column>
				<Column field="last_name" header="Last name" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.last_name }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['last_name']" class="p-column-filter" />
					</template>
				</Column>
				<Column field="email" header="E-mail" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.email }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['email']" class="p-column-filter" />
					</template>
				</Column>
				<Column field="external_id" header="Code" :sortable="true" filterMatchMode="contains">
					<template #body="slotProps">
						{{ slotProps.data.external_id }}
					</template>
					<template #filter>
						<InputText type="text" v-model="filters['external_id']" class="p-column-filter" style="width:100px;" />
					</template>
				</Column>
				<Column field="locale" header="Language" :sortable="true" filterMatchMode="equals">
					<template #body="slotProps">
						{{ slotProps.data.locale }}
					</template>
					<template #filter>
						<Dropdown v-model="filters['locale']" :options="locales" optionLabel="label" optionValue="value" />
					</template>
				</Column>
				<Column field="last_access_on" header="Last Admin access" :sortable="true" filterMatchMode="equals">
					<template #body="slotProps">
						{{ slotProps.data.last_access_on }}
					</template>
					<template #filter>
						<Dropdown v-model="filters['last_access_on']" :options="locales" optionLabel="label" optionValue="value" />
					</template>
				</Column>
				<template #expansion="slotProps">
					<div>
						<div v-if="slotProps.data.activity" class="p-datatable-sm" style="padding-left:30px;">
							<DataTable :value="slotProps.data.activity" sortField="action_on" :sortOrder="-1">
								<Column field="action" header="Action" :sortable="true">
									<template #body="slotProps">
										{{ slotProps.data.action }}
									</template>
									<template #filter>
										<InputText type="text" v-model="filters['action']" class="p-column-filter" />
									</template>
								</Column>
								<Column field="action_on" header="Date" :sortable="true">
									<template #body="slotProps">
										{{ slotProps.data.action_on }}
									</template>
									<template #filter>
										<InputText type="text" v-model="filters['action_on']" class="p-column-filter" />
									</template>
								</Column>
								<Column field="ip" header="IP" :sortable="true">
									<template #body="slotProps">
										{{ slotProps.data.ip }}
									</template>
									<template #filter>
										<InputText type="text" v-model="filters['ip']" class="p-column-filter" />
									</template>
								</Column>
							</DataTable>
						</div>
					</div>
				</template>
			</DataTable>
		</div>
	</layout-default>
</template>

<style>
.p-dataview .p-dataview-content {
	background: transparent !important;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";
import Axios from "axios";

import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,
		Axios,

		DataTable,
		DataView,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		SplitButton,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			users: null,
			filters: {},
			statuses: [
				{ label: "Invited", value: "invited" },
				{ label: "Active", value: "active" },
			],
			locales: [
				{ label: "nl-NL", value: "nl-NL" },
				{ label: "fr-FR", value: "fr-FR" },
			],
			expandedRows: [],
		};
	},
	props: {},
	methods: {
		onRowExpand(event) {
			console.log(event.data);
			Directus.client.api
				.get("/activity", {
					limit: -1,
					"filter[action_by][eq]": event.data.id,
					sort: "-action_on",
					fields: "*",
				})
				.then((response) => {
					let activity = response.data;
					this.$set(event.data, "activity", activity);
				});
		},
		onRowCollapse(event) {},
	},

	computed: {
		user_id() {
			return this.$route.params.user_id;
		},
	},

	created() {
		Directus.client.api
			.get("/users", {
				limit: -1,
				//"filter[translation.language][eq]": "en-US",
				fields: "*.*",
			})
			.then((response) => {
				this.users = response.data;
			});
	},
	mounted: function() {},
	watch: {
		$route(to, from) {},
	},
};
</script>

<style lang="scss"></style>
