<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>

			<div
				class=""
				v-show="loading == false && content && content.length>0"
			>
				<div class="uk-container uk-container-large typography-main">
					<div class="p-mb-6"></div>

					<div class="uk-grid">
						<div class="uk-width-1-1 uk-width-expand@l p-mb-5">
							<Card
								v-if="content"
								class="p-mb-4 uk-hidden@l"
							>
								<template slot="content">
									<div class=" typography-main">
										<div v-html="content[0].html"></div>
									</div>
								</template>
							</Card>

							<div>
								<Carousel
									:value="banners"
									:numVisible="1"
									:numScroll="1"
									:autoplayInterval="5000"
									:circular="true"
								>
									<template #item="slotProps">
										<div
											class="uk-position-relative uk-visible@s"
											v-if="slotProps.data && slotProps.data.image && slotProps.data.image.data"
										>
											<img
												style="margin:0px;display:block;"
												:src="remote_addr + slotProps.data.image.data.asset_url + ''"
												class="br uk-width-1-1"
											/>
										</div>
										<div
											class="uk-position-relative uk-hidden@s"
											v-if="slotProps.data && slotProps.data.image_mobile && slotProps.data.image_mobile.data"
										>
											<img
												style="margin:0px;display:block;"
												:src="remote_addr + slotProps.data.image_mobile.data.asset_url + ''"
												class="br uk-width-1-1"
											/>
										</div>
									</template>
								</Carousel>
							</div>
							<div class="p-mb-3"></div>

							<div class="uk-grid uk-flex-left uk-child-width-1-2@m">
								<div
									v-for="(promotion, index) in promotions"
									:key="index"
									class="p-mb-5"
								>
									<Card>
										<template slot="header">
											<div
												class="uk-position-relative"
												v-aspect-ratio="'4:3'"
											>
												<img
													v-if="promotion && promotion.image"
													class="uk-position-absolute"
													:src="remote_addr + promotion.image.data.asset_url + '?w=800&h=600&q=100&f=contain'"
													style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain;"
												/>
											</div>
										</template>
										<template slot="title">
											{{ getLanguageField(promotion, "title") }}
										</template>
										<template slot="subtitle">
											{{ getLanguageField(promotion, "subtitle") }}
										</template>
										<template slot="content">
											<div v-html="promotion && promotion.content_short"></div>
										</template>
										<template slot="footer">
											<div class="uk-grid uk-grid-small uk-flex-middle">
												<div class="uk-width-expand">
													<Button
														icon="pi pi-arrow-right"
														label="Lees meer"
														@click="gotoPromotion(promotion.id)"
													/>
												</div>
												<div
													class="uk-width-1-4"
													v-if="promotion && promotion.image2"
												>
													<img
														:src="remote_addr + promotion.image2.data.asset_url + '?w=400&h=400&q=100&f=contain'"
														class="uk-width-1-1"
														@click="lightBoxShow('Sigma Perfect', require('@/assets/promoties/sigmaperfect.png'))"
													/>
												</div>
											</div>
										</template>
									</Card>
								</div>

								<div
									v-for="(newsitem, index) in news"
									:key="index"
									class="p-mb-5"
								>
									<Card>
										<template
											slot="header"
											v-if="newsitem && newsitem.image"
										>
											<div
												class="uk-position-relative"
												v-aspect-ratio="'4:3'"
											>
												<img
													class="uk-position-absolute"
													:src="remote_addr + newsitem.image.data.asset_url + '?w=800&h=600&q=100&f=contain'"
													style="position: absolute;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;"
												/>
											</div>
										</template>
										<template slot="title">
											{{ getLanguageField(newsitem, "title") }}
										</template>
										<template slot="subtitle">
											{{ getLanguageField(newsitem, "subtitle") }}
										</template>
										<template slot="content">
											<div v-html="newsitem && newsitem.content_short"></div>
										</template>
										<template slot="footer">
											<div class="uk-grid uk-grid-small uk-flex-middle">
												<div class="uk-width-expand">
													<Button
														icon="pi pi-arrow-right"
														label="Lees meer"
														@click="gotoNews(newsitem.id)"
													/>
												</div>
												<div
													class="uk-width-1-4"
													v-if="newsitem && newsitem.image2"
												>
													<img
														:src="remote_addr + newsitem.image2.data.asset_url + '?w=400&h=400&q=100&f=contain'"
														class="uk-width-1-1"
														@click="lightBoxShow('Sigma Perfect', require('@/assets/promoties/sigmaperfect.png'))"
													/>
												</div>
											</div>
										</template>
									</Card>
								</div>
							</div>

							<div class="p-mb-6"></div>
						</div>

						<div class="uk-width-1-1 uk-width-1-3@l uk-width-1-4@xl p-mb-5">
							<Card
								v-if="content"
								class="p-mb-4 uk-visible@l"
							>
								<template slot="content">
									<div class=" typography-main">
										<div v-html="content[0].html"></div>
									</div>
								</template>
							</Card>
							<div v-if="sideButtons">
								<div
									v-for="(sideButton, index) in sideButtons"
									:key="index"
									style="cursor:pointer;"
									class="p-mb-3"
									@click="gotoPage(sideButton)"
								>
									<Card>
										<template slot="content">
											<div class="uk-position-relative">
												<div class="uk-grid uk-grid-small uk-flex-center">
													<div class="uk-width-auto">
														<div
															class="t-fg-1"
															v-if="sideButton.icon"
														>
															<img
																:src="'/images/streamline/' + sideButton.icon"
																style="width:80%;max-width:64px;margin-bottom:-8px"
															/>
														</div>
													</div>
													<div class="uk-width-expand">
														<h3 style="margin:0px;padding:0px;">{{ sideButton.title }}</h3>
													</div>
												</div>
											</div>
										</template>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</layout-default>
</template>

<style>
.p-carousel-prev,
.p-carousel-next {
	display: none !important;
}
</style>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import Carousel from "primevue/carousel";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		Carousel,
		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			loading: true,

			content: [{}],

			promotions: [],
			news: [],

			banners: null,

			sideButtons: [],
		};
	},
	props: {},
	methods: {
		getExtension(filename) {
			var re = /(?:\.([^.]+))?$/;
			return re.exec(filename)[1];
		},
		getLanguageField(obj, field) {
			return Directus.getLanguageField(obj, field, this.$i18n.locale);
		},
		gotoNews(id) {
			this.$router.push({
				name: "news",
				params: {
					id: id,
				},
			});
		},
		gotoPromotion(id) {
			this.$router.push({
				name: "promotions",
				params: {
					id: id,
				},
			});
		},
		gotoPage(item) {
			this.$router.push({
				name: item.module,
				params: {
					slug: item.slug,
					lang: this.$i18n.locale == "nl" ? null : this.$i18n.locale,
					menu: item,
				},
			});
		},
	},
	computed: {},
	created() {
		Directus.client.api
			.get("/items/banners", {
				"filter[status][eq]": "published",
				"filter[date_show_on_website][lte]": "now",
				"filter[date_hide_from_website][gte]": "now",
				fields: "*,image.*,image_mobile.*",
				sort: "-sort",
			})
			.then((response) => {
				const banners = response.data;
				banners.forEach((banner) => {
					if (!banner.image_mobile || !banner.image_mobile.data) {
						banner.image_mobile = banner.image;
					}
				});
				this.banners = banners;

				Directus.client.api
					.get("/items/promotions", {
						"filter[status][eq]": "published",
						"filter[show_on_homepage][eq]": "1",
						fields: "*,translation.*,image.*,image2.*,files.directus_files_id.*",
					})
					.then((response) => {
						this.promotions = response.data;

						Directus.client.api
							.get("/items/news", {
								"filter[status][eq]": "published",
								"filter[show_on_homepage][eq]": "1",
								fields: "*,image.*,translation.*",
								sort: "-date",
							})
							.then((response) => {
								this.news = response.data;

								Directus.client.api
									.get("/items/menu", {
										"filter[status][eq]": "published",
										"filter[type][eq]": "Main",
										"filter[show_on_homepage][eq]": "1",
										fields: "*,translation.*",
									})
									.then((response) => {
										this.sideButtons = response.data;

										Directus.client.api
											.get("/items/content", {
												"filter[status][eq]":
													"published",
												"filter[slug][eq]": "home",
												fields: "*",
											})
											.then((response) => {
												this.content = response.data;

												this.loading = false;
											});
									});
							});
					});
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
