<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-1-1 p-pb-3">
							<h1>Merken</h1>
						</div>

						<div class="uk-width-1-1 p-pb-3">
							<div v-html="content[0].html"></div>
						</div>
					</div>
					<div class="uk-grid typography-main">
						<div
							class="uk-width-1-2 uk-width-1-3@s uk-width-1-4@m p-mb-6"
							v-for="(brand, index) in brands"
							:key="index"
						>
							<Card>
								<template slot="content">
									<div
										class="uk-position-relative"
										v-aspect-ratio="'1:1'"
										v-if="brand && brand.logo && brand.logo.data"
									>
										<img
											class="uk-position-absolute"
											:src="remote_addr + brand.logo.data.asset_url + '?w=800&h=400&q=100&f=contain'"
											style="position: absolute; padding:20px;
													top: 0;
													left: 0;
													width: 100%;
													height: 100%;
													object-fit: contain;
													"
										/>
									</div>
									<div
										class="uk-position-relative"
										v-aspect-ratio="'1:1'"
										v-else
									>
										<div
											class="uk-position-absolute"
											style="position: absolute; padding:20px; top: 0; left: 0; width: 100%; height: 100%; object-fit: contain; cursor:pointer;"
										>{{ brand.name }}</div>
									</div>
								</template>
								<template slot="footer">
									<img
										v-if="brand.url"
										v-tooltip.top="'Ga naar de website'"
										src="/images/social/external.webp"
										style="max-height:28px;"
										class="p-mr-2"
										@click="openUrl(brand.url)"
									/>
									<img
										v-if="brand.url_linkedin"
										v-tooltip.top="'Linkedin'"
										src="/images/social/linkedin.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_linkedin)"
									/>
									<img
										v-if="brand.url_facebook"
										v-tooltip.top="'Facebook'"
										src="/images/social/facebook.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_facebook)"
									/>
									<img
										v-if="brand.url_instagram"
										v-tooltip.top="'Instagram'"
										src="/images/social/instagram.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_instagram)"
									/>
									<img
										v-if="brand.url_twitter"
										v-tooltip.top="'Twitter'"
										src="/images/social/twitter.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_twitter)"
									/>
									<img
										v-if="brand.url_pintereset"
										v-tooltip.top="'Pinterest'"
										src="/images/social/pinterest.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_pinterest)"
									/>
									<img
										v-if="brand.url_youtube"
										v-tooltip.top="'YouTube'"
										src="/images/social/youtube.webp"
										class="p-mr-2"
										style="max-height:28px;"
										@click="openUrl(brand.url_youtube)"
									/>
								</template>
							</Card>
						</div>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>
		</div>
	</layout-default>
</template>

<script>
import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,

			brands: [],
			content: [{}],
		};
	},
	props: {},
	methods: {
		openUrl(url) {
			if (url) {
				window.open(url);
			}
		},
	},
	computed: {},
	created() {
		Directus.client.api
			.get("/items/brands", {
				"filter[status][eq]": "published",
				"filter[url][nempty]": "",
				fields: "*,logo.*",
			})
			.then((response) => {
				this.brands = response.data;
				console.log(this.brands);
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "merken",
				fields: "*",
			})
			.then((response) => {
				this.content = response.data;
			});
	},
	mounted: function () {},
};
</script>

<style lang="scss"></style>
