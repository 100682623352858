<template>
	<layout-default>
		<div>
			<div
				class="uk-position-center"
				v-if="loading == true"
			>
				<ProgressSpinner></ProgressSpinner>
			</div>
			<div
				class=""
				v-if="loading == false && content && content.length>0"
			>
				<div class="p-mb-6"></div>

				<div class="uk-container uk-container-large">
					<div class="uk-grid typography-main">
						<div class="uk-width-expand p-pb-3">
							<h1>Social media</h1>
						</div>
					</div>
					<div class="typography-main">
						<div
							class="uk-width-expand p-pb-3"
							v-if="content && content[0] && content[0].html"
						>
							<div v-html="content[0].html"></div>
						</div>
					</div>

					<div>
						<div
							class="uk-width-expand p-pb-6"
							v-if="content && content[0] && content[0].html"
						>
							<Button
								class="p-button-sm p-mr-1 p-px-2 p-py-1"
								:class="currentMonth == month.code ? '' : 'p-button-outlined'"
								v-for="month in months"
								:key="month.code"
								:label="month.text"
								@click="currentMonth = month.code"
							/>
						</div>
					</div>

					<div
						v-for="(socialmedia, index) in socialmediaItemsFiltered"
						:key="index"
						class="p-mb-6"
					>
						<div
							v-if="socialmedia.category"
							class="typography-main"
						>
							<h2>{{socialmedia.category}}</h2>
						</div>
						<Card>
							<template slot="content">
								<div class="uk-grid">
									<div class="uk-width-1-1 uk-width-1-3@s uk-width-1-4@m">
										<div v-if="socialmedia.image && socialmedia.image.data">
											<div>
												<img
													class="uk-width-1-1 p-shadow-8"
													:src="remote_addr + socialmedia.image.data.asset_url"
												/>
												<Button
													class="p-button-secondary p-button-outlined p-button-sm"
													style=""
													type="button"
													@click="downloadImage(socialmedia.image)"
												>Download</Button>
											</div>
										</div>
									</div>
									<div class="uk-width-1-1 uk-width-2-3@s uk-width-3-4@m">
										<div class="typography-main">
											<h2>{{ socialmedia.title }}</h2>
											<div>Titel:</div>
											<div
												class="p-button p-button-outlined p-button-secondary p-mb-3"
												style="width:100%;text-align:left;position:relative;"
												v-clipboard:copy="socialmedia.title"
												v-clipboard:success="onCopy"
												v-clipboard:error="onError"
											>
												<div style="padding-right:80px;">{{ socialmedia.title }}</div>
												<div>
													<Button
														class="p-button-secondary p-button-outlined p-button-sm"
														style="padding:0px 8px;position:absolute;bottom:3px;right:3px;"
														type="button"
													>Kopieer</Button>
												</div>
											</div>
											<div>Text:</div>
											<div
												class="p-button p-button-outlined p-button-secondary p-mb-3"
												style="width:100%;text-align:left;position:relative;"
												v-clipboard:copy="socialmedia.content"
												v-clipboard:success="onCopy"
												v-clipboard:error="onError"
											>
												<div style="padding-right:80px;">{{ socialmedia.content }}</div>
												<div>
													<Button
														class="p-button-secondary p-button-outlined p-button-sm"
														style="padding:0px 8px;position:absolute;bottom:3px;right:3px;"
														type="button"
													>Kopieer</Button>
												</div>
											</div>
											<div v-if="socialmedia.links">
												<div>Link<span v-if="socialmedia.links.length > 1">s</span></div>
												<div
													v-for="(link, index2) in socialmedia.links"
													:key="index2"
												>
													<div
														class="p-button p-button-outlined p-button-secondary p-mb-3"
														style="width:100%;text-align:left;position:relative;"
														v-clipboard:copy="link.url"
														v-clipboard:success="onCopy"
														v-clipboard:error="onError"
													>
														<div style="padding-right:80px;">{{ link.url }}</div>
														<div>
															<Button
																class="p-button-secondary p-button-outlined p-button-sm"
																style="padding:0px 8px;position:absolute;bottom:3px;right:3px;"
																type="button"
															>Kopieer</Button>
														</div>
													</div>
												</div>
											</div>
											<div v-if="socialmedia.colors">
												<div>Kleur<span v-if="socialmedia.colors.length > 1">en</span></div>
												<div
													v-for="(color, index2) in socialmedia.colors"
													:key="index2"
												>
													<div
														class="p-button p-button-outlined p-button-secondary p-mb-3"
														style="width:100%;text-align:left;position:relative;"
														v-clipboard:copy="color.name"
														v-clipboard:success="onCopy"
														v-clipboard:error="onError"
													>
														<div style="padding-right:80px;">{{ color.name }}</div>
														<div>
															<Button
																class="p-button-secondary p-button-outlined p-button-sm"
																style="padding:0px 8px;position:absolute;bottom:3px;right:3px;"
																type="button"
															>Kopieer</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</Card>
					</div>
				</div>

				<div class="p-mb-6"></div>
			</div>

			<Dialog
				:header="activeTraining && activeTraining.title ? activeTraining.title : ''"
				:visible.sync="displayTraining"
			>
				<div v-if="activeTraining">
					<iframe
						id="trainingIframe"
						:src="'https://ppgtechnicalacademy.com/mod/scorm/player.php?a=26&currentorg=ProGold_kitten_en_vulmiddelen_ORG&scoid=956&sesskey=pxBaqQGnTN&display=popup&mode=normal'"
						style="display:block;border:0px;width:calc(100vw - 32px);height:calc(100vh - 262px);padding:0px;margin:0px;"
					></iframe>
				</div>
			</Dialog>
			<Toast />
		</div>
	</layout-default>
</template>

<script>
const axios = require("axios");
const parseString = require("xml2js").parseString;

import LayoutDefault from "@/layout/Default.vue";
import Directus from "@/services/directus/";

import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";

import Card from "primevue/card";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Message from "primevue/message";
import Steps from "primevue/steps";
import Tree from "primevue/tree";
import SelectButton from "primevue/selectbutton";
import Dropdown from "primevue/dropdown";
import Sidebar from "primevue/sidebar";
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";
import MultiSelect from "primevue/multiselect";
import VueBarcode from "@chenfengyuan/vue-barcode";

import moment from "moment";

export default {
	components: {
		LayoutDefault,
		Directus,

		ProgressSpinner,
		DataTable,
		Column,
		ColumnGroup,

		TabView,
		TabPanel,
		InputText,
		Textarea,
		Card,
		Button,
		Toast,
		Message,
		Steps,
		Tree,
		SelectButton,
		Dropdown,
		Sidebar,
		Dialog,
		Editor,
		MultiSelect,
		VueBarcode,
	},
	data() {
		return {
			loading: false,
			remote_addr: process.env.VUE_APP_REMOTE_ADDR,
			content: [{}],
			activeTraining: null,
			displayTraining: false,
			socialmediaItems: null,
			currentMonth: null,
			months: [
				{ code: "JAN", text: "Januari" },
				{ code: "FEB", text: "Februari" },
				{ code: "MAR", text: "Maart" },
				{ code: "APR", text: "April" },
				{ code: "MAY", text: "Mei" },
				{ code: "JUN", text: "Juni" },
				{ code: "JUL", text: "Juli" },
				{ code: "AUG", text: "Augustus" },
				{ code: "SEP", text: "September" },
				{ code: "OCT", text: "Oktober" },
				{ code: "NOV", text: "November" },
				{ code: "DEC", text: "December" },
			],
		};
	},
	props: {},
	methods: {
		onCopy: function (e) {
			this.$toast.add({
				severity: "success",
				summary: "Gekopieerd",
				detail: "Naar klembord gekopiëerd: " + this.truncate(e.text),
				life: 3000,
			});
		},
		onError: function (e) {
			this.$toast.add({
				severity: "error",
				summary: "Fout",
				detail: "Kon niet kopiëren naar klembord",
				life: 3000,
			});
		},
		truncate(input) {
			if (input.length > 50) {
				return input.substring(0, 50).trim() + "...";
			}
			return input;
		},
		async downloadImage(imageObj) {
			console.log(imageObj);
			const image = await fetch(imageObj.data.asset_url);
			const imageBlog = await image.blob();
			const imageURL = URL.createObjectURL(imageBlog);

			const link = document.createElement("a");
			link.href = imageURL;
			link.download = imageObj.filename_download;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	},

	computed: {
		socialmediaItemsFiltered() {
			let self = this;
			return _.filter(self.socialmediaItems, function (item) {
				if (item.months) {
					console.log(item.months);
					if (_.includes(item.months, self.currentMonth)) {
						return true;
					}
				}
				return false;
			});
		},
	},

	mounted() {
		let self = this;

		Directus.client.api
			.get("/items/socialmedia", {
				"filter[status][eq]": "published",
				"filter[date_show_on_website][lte]": "now",
				"filter[date_hide_from_website][gte]": "now",
				fields: "*,image.*",
			})
			.then((response) => {
				this.socialmediaItems = response.data;
			});

		Directus.client.api
			.get("/items/content", {
				"filter[status][eq]": "published",
				"filter[slug][eq]": "socialmedia",
				fields: "*",
			})
			.then((response) => {
				if (response && response.data) {
					this.content = response.data;
				}
			});
	},
	created: function () {
		this.currentMonth = this.months[Number(moment().format("M")) - 1].code;
	},
};
</script>

<style lang="scss"></style>
